import React from "react";
import { Route ,Switch } from 'react-router-dom'
import DesiredTraits from "../layout/pages/CareersPage/DesiredTraits";
import JobOpportunities from "../layout/pages/CareersPage/JobOpportunities";

class CareersRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const url = this.props.url;
    return (
      <Switch>
          <Route path={`${url}/desired-traits`} component={DesiredTraits} />
          <Route path={`${url}/job-opportunities`} component={JobOpportunities} />
      </Switch>
    );
  }
}

export default CareersRouter;
