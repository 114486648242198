import React from "react";
import { FormattedHTMLMessage, defineMessages, injectIntl } from "react-intl";
import { Fade } from "react-reveal";
import { Container } from "reactstrap";

const messages = defineMessages({
  header: {
    id: "BE.askForEmail.header",
    defaultMessage: "A business-friendly <br/>Website & Hotel Booking Engine",
  },
  askForEmail: {
    id: "BE.askForEmail.askForEmail",
    defaultMessage: "Ask for mail ",
  },
});
class BEAskForEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <section className="be-ask-for-email-section">
        <Fade distance="2rem" bottom>
          <Container className="section-content">
            <h5>
              <FormattedHTMLMessage {...messages.header} />
            </h5>
            <a href="mailto:cs@ohmyhotel.com" className="btn btn-primary">
              <img src="/images/icons/new-email-outline.svg" alt="" />
              {formatMessage(messages.askForEmail)}
              <img src="/images/icons/chevron-white.svg" alt=">" />
            </a>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(BEAskForEmail);
