import React from "react";
import { FormattedHTMLMessage, defineMessages, injectIntl } from "react-intl";
import { Fade } from "react-reveal";
import { Col, Container, Row } from "reactstrap";

const messages = defineMessages({
  header1: {
    id: "OTA.partner.header1",
    defaultMessage: "Visit the",
  },
  websiteLink: {
    id: "OTA.partner.websiteLink",
    defaultMessage: "Ohmyhotel website ",
  },
  learnMore: {
    id: "OTA.partner.learnMore",
    defaultMessage: "Learn More",
  },
  header2: {
    id: "OTA.partner.header2",
    defaultMessage: "Ohmyhotel partner",
  },
  content: {
    id: "OTA.partner.content",
    defaultMessage:
      "I'd like to sell our hotel, too. <br/>I want to partner with our online channel. <br/>I'm interested in system purchases.",
  },
  titte: {
    id: "OTA.partner.titte",
    defaultMessage: "Would you be our partner?",
  },
  askForEmail: {
    id: "OTA.partner.askForEmail",
    defaultMessage: "Ask for mail",
  },
});
class OTAPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <section className="ota-partner-section">
        <Fade distance="2rem" bottom>
          <Container>
            <Row>
              <Col md="6" sm="12">
                <div className="section-content">
                  <img
                    src="/images/business/OTA/hbe-logo.svg"
                    alt="website"
                    height="88"
                    width="66"
                  />
                  <div className="align-left">
                    <div className="p-title">
                      <FormattedHTMLMessage {...messages.header1} />
                      {/* //{formatMessage(messages.header1)} */}
                    </div>

                    <h5>
                      <a
                        href="https://www.ohmyhotel.com/"
                        className=""
                        target="_blank"
                        rel="noopener noreferrer">
                        {formatMessage(messages.websiteLink)}
                        <img
                          src="/images/icons/chevron.svg"
                          alt=">"
                          height="16"
                        />
                      </a>
                    </h5>
                    <span className="header-line content-left" />
                  </div>
                </div>
              </Col>
              <Col md="6" sm="12" className="align-left">
                <div className="section-header">
                  <h3>{formatMessage(messages.header2)}</h3>
                  <span className="header-line content-left" />
                </div>
                <div className="section-content">
                  <FormattedHTMLMessage tagName="p" {...messages.content} />
                  <h6 className="p-title">{formatMessage(messages.titte)}</h6>
                  <a href="mailto:cs@ohmyhotel.com" className="btn btn-primary">
                    <img src="/images/icons/new-email-outline.svg" alt="" />
                    {formatMessage(messages.askForEmail)}
                    <img src="/images/icons/chevron-white.svg" alt=">" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(OTAPartner);
