import React from "react";
import PMSIntro from "./PMSIntro";
import PMSJustInTime from "./PMSJustInTime";
import PMSAskForEmail from "./PMSAskForEmail";

import "./PMS.scss";

class PMS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <PMSIntro />
        <PMSJustInTime />
        <PMSAskForEmail />
      </div>
    );
  }
}

export default PMS;
