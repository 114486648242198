import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "BE.webDesign.header",
    defaultMessage:
      "More valuable experience than staying,<br/> Sell your room with a business-friendly website."
  },
  commonContent: {
    id: "BE.webDesign.commonContent",
    defaultMessage:
      "From search to payment, real-time hotel booking allows you to increase your reservation directly and to offer a professional website that is easy to use. Our hotel web designer team listens carefully to our customers' requests and puts top priority on web design for your type of accommodation."
  },
  title1: {
    id: "BE.webDesign.title1",
    defaultMessage: "Design"
  },
  title2: {
    id: "BE.webDesign.title2",
    defaultMessage: "Template"
  },
  title3: {
    id: "BE.webDesign.title3",
    defaultMessage: "Booking"
  },
  content1: {
    id: "BE.webDesign.content1",
    defaultMessage:
      "It shows our hotel better,<br />Increase user confidence in the brand."
  },
  content2: {
    id: "BE.webDesign.content2",
    defaultMessage:
      "Optimized templates for the latest browsers and mobile. <p>We can promote our hotel to travelers around the world through various language programs.</p>"
  },
  content3: {
    id: "BE.webDesign.content3",
    defaultMessage:
      "Try paying online.<br />It's a two-step scheduling process that's easy and convenient."
  }
});
class BEWebDesign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <section className="be-website-section" id="beWebsite">
        <Fade distance="2rem" bottom>
          <div className="section-header">
            <FormattedHTMLMessage tagName="h3" {...messages.header} />
            <span className="header-line" />
          </div>
          <Container className="common-content">
            <FormattedHTMLMessage {...messages.commonContent} />
          </Container>
          <Container className="section-content">
            <Row>
              <Col 
                md={{ size: 6, order: 1 }} 
                sm={{ size: 12, order: 1 }}
                xs={{ size: 12, order: 1 }}
              >
                <img src="/images/business/BE/design.jpg" alt="" />
              </Col>
              <Col
                md={{ size: 6, order: 2 }}
                sm={{ size: 12, order: 2 }}
                xs={{ size: 12, order: 2 }}
                className="align-left"
              >
                <h6 className="p-title">{formatMessage(messages.title1)}</h6>
                <FormattedHTMLMessage tagName="p" {...messages.content1} />
              </Col>
              <Col
                md={{ size: 6, order: 3 }}
                sm={{ size: 12, order: 4 }}
                xs={{ size: 12, order: 4 }}
                className="align-right"
              >
                <h6 className="p-title">{formatMessage(messages.title2)}</h6>
                <FormattedHTMLMessage tagName="p" {...messages.content2} />
              </Col>
              <Col
                md={{ size: 6, order: 4 }}
                sm={{ size: 12, order: 3 }}
                xs={{ size: 12, order: 3 }}
              >
                <img src="/images/business/BE/template.jpg" alt="" />
              </Col>
              <Col 
                md={{ size: 6, order: 5 }} 
                sm={{ size: 12, order: 5 }}
                xs={{ size: 12, order: 5 }}
              >
                <img src="/images/business/BE/booking.jpg" alt="" />
              </Col>
              <Col
                md={{ size: 6, order: 6 }}
                sm={{ size: 12, order: 6 }}
                xs={{ size: 12, order: 6 }}
                className="align-left"
              >
                <h6 className="p-title">{formatMessage(messages.title3)}</h6>
                <FormattedHTMLMessage tagName="p" {...messages.content3} />
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(BEWebDesign);
