import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Zoom } from "react-reveal";
const messages = defineMessages({
  title1: {
    id: "business.banner.title1",
    defaultMessage: "Whoever, Whenever, Wherever Travel"
  }
});
class BusinessBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    var movementStrength = 50;
    var height =
      movementStrength / document.getElementById("businessBanner").clientWidth;
    var width =
      movementStrength / document.getElementById("businessBanner").clientWidth;
    function updateBannerPosition(e) {
      var pageX =
        e.pageX - document.getElementById("businessBanner").clientWidth / 2;
      var pageY =
        e.pageY - document.getElementById("businessBanner").clientHeight / 2;
      var newvalueX = width * pageX * -1 + 10;
      var newvalueY = height * pageY * -1;

      document.getElementById("businessBanner").style.backgroundPosition =
        "10% -5% , 90% 115%,  calc( 50% + " +
        newvalueX +
        "px) calc( 28% + " +
        newvalueY +
        "px)";
    }
    if (!(!!window.MSInputMethodContext && !!document.documentMode)) {
      document.getElementById(
        "businessBanner"
      ).onmousemove = updateBannerPosition;
    }
  }
  render() {
    return (
      <section className="business-banner" id="businessBanner">
        <div>
          <Zoom>
            <FormattedHTMLMessage tagName="h2" {...messages.title1} />
          </Zoom>
        </div>
      </section>
    );
  }
}

export default injectIntl(BusinessBanner);
