import React from "react";
import { FormattedHTMLMessage, defineMessages, injectIntl } from "react-intl";
import { Fade } from "react-reveal";
import { Container } from "reactstrap";

const messages = defineMessages({
  header: {
    id: "PMS.askForEmail.header",
    defaultMessage:
      "Just the right online booking engine to use on your company's",
  },
  askForEmail: {
    id: "PMS.askForEmail.askForEmail",
    defaultMessage: "Ask for mail ",
  },
});
class PMSAskForEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <section className="pms-afe-section">
        <Fade distance="2rem" bottom>
          <Container className="section-content">
            <FormattedHTMLMessage tagName="h5" {...messages.header} />
            <a href="mailto:cs@ohmyhotel.com" className="btn btn-primary">
              <img src="/images/icons/new-email-outline.svg" alt="" />
              {formatMessage(messages.askForEmail)}
              <img src="/images/icons/chevron-white.svg" alt=">" />
            </a>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(PMSAskForEmail);
