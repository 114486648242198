import React from "react";
import HomeBanner from "./HomeBanner";
import HomeTwoThings from "./HomeTwoThings";
import HomeAllInOne from "./HomeAllInOne";
import HomeSimpleIntuitive from "./HomeSimpleIntuitive";
import HomeYourFriendConfidant from "./HomeYourFriendConfidant";

import "./HomePage.scss";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }
  componentDidMount() {
    // window.scrollTo(0, 0);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return (
      <div>
        <HomeBanner />
        <HomeTwoThings />
        <HomeAllInOne />
        <HomeSimpleIntuitive />
        <HomeYourFriendConfidant />
      </div>
    );
  }
}

HomePage.propTypes = {};

export default HomePage;
