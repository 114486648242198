import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "home.simpleIntuitive.header",
    defaultMessage: "Simple and intuitive asset management system"
  },
  content: {
    id: "home.simpleIntuitive.content",
    defaultMessage:
      "The most ideal system for selling as many rooms as possible. <br/>Enjoy all of these benefits with a strategic partnership."
  }
});

class HomeSimpleIntuitive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="simple-intuitive-section">
        <Fade distance="2rem" bottom>
          <div className="section-header align-center">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </div>
          <Container className="section-content">
            <FormattedHTMLMessage tagName="p" {...messages.content} />
            {/* <img
              className="for-desktop"
              src="/images/home/management-system.jpg"
              width="828"
              alt=""
            />
            <img
              className="for-mobile"
              src="/images/home/m-management-system.jpg"
              alt=""
            /> */}
            <div className="management-system">
              <picture>
                <source
                  media="(max-width: 767.98px)"
                  srcSet="/images/home/m-management-system.jpg"
                />
                <img
                  src="/images/home/management-system.jpg"
                  width="828"
                  alt=""
                />
              </picture>
            </div>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(HomeSimpleIntuitive);
