import React from "react";
import PrivacyBanner from "./PrivacyBanner";
import PrivacyContent from "./PrivacyContent";
import "./PrivacyPage.scss";
class PrivacyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return (
      <div>
        <PrivacyBanner />
        <PrivacyContent />
      </div>
    );
  }
}

export default PrivacyPage;
