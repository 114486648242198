import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "CI.colorSystem.header",
    defaultMessage: "Color System"
  },
  reference: {
    id: "CI.colorSystem.reference",
    defaultMessage: "Reference"
  },
  gradientView: {
    id: "CI.colorSystem.gradientView",
    defaultMessage: "Gradient View"
  },
  content: {
    id: "CI.colorSystem.content",
    defaultMessage:
      "Ohmyhotel & Co colors are a key factor in establishing corporate identity.<br/> For effective use, the designated colors should be used in accordance to the characteristics of the medium. Upon significant differences in brightness, chroma or other aspects, the color should be as close to pantone color as possible."
  }
});
class ColorSystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <section className="color-system-section">
        <Fade distance="2rem" bottom>
          <Container className="section-header">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </Container>
          <div className="section-content">
            <Container>
              <FormattedHTMLMessage tagName="p" {...messages.content} />

              <Row className="content-center">
                <Col sm="6" md="3" className="color-item">
                  <div className="color-cycle orange-gradient" />

                  <div>
                    <p>
                      <strong>{formatMessage(messages.reference)}</strong>
                    </p>
                    <p>{formatMessage(messages.gradientView)}</p>
                  </div>
                </Col>
                <Col sm="6" md="3" className="color-item">
                  <div className="color-cycle green-gradient" />

                  <div>
                    <p>
                      <strong>{formatMessage(messages.reference)}</strong>
                    </p>
                    <p>{formatMessage(messages.gradientView)}</p>
                  </div>
                </Col>
                <Col sm="6" md="3" className="color-item">
                  <div className="color-cycle orange" />

                  <div>
                    <p>
                      <strong>PANTONE</strong>: 1575C
                    </p>
                    <p>
                      <strong>CMYK</strong>: 00/ 62/ 86/ 00
                    </p>
                    <p>
                      <strong>RGB</strong>: 255/ 126/ 46
                    </p>
                  </div>
                </Col>
                <Col sm="6" md="3" className="color-item">
                  <div className="color-cycle green" />

                  <div>
                    <p>
                      <strong>PANTONE</strong>: 355C
                    </p>
                    <p>
                      <strong>CMYK</strong>: 98/ 12/ 100/ 02
                    </p>
                    <p>
                      <strong>RGB</strong>: 00/ 149/ 5
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(ColorSystem);
