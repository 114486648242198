import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Zoom } from "react-reveal";

const messages = defineMessages({
  title1: {
    id: "aboutUs.banner.title1",
    defaultMessage:
      "<h2>Realize value</h2><img src='/images/logo/name-white.svg' height='27' alt='' />"
  }
});

class AboutUsBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    var movementStrength = 50;
    var height =
      movementStrength / document.getElementById("aboutBanner").clientWidth;
    var width =
      movementStrength / document.getElementById("aboutBanner").clientWidth;
    function updateBannerPosition(e) {
      var pageX =
        e.pageX - document.getElementById("aboutBanner").clientWidth / 2;
      var pageY =
        e.pageY - document.getElementById("aboutBanner").clientHeight / 2;
      var newvalueX = width * pageX * -1;
      var newvalueY = height * pageY * -1;

      document.getElementById("aboutBanner").style.backgroundPosition =
        "10% -5% , 90% 115%,  calc( 50% + " +
        newvalueX +
        "px) calc( 50% + " +
        newvalueY +
        "px)";
    }
    if (!(!!window.MSInputMethodContext && !!document.documentMode)) {
      document.getElementById("aboutBanner").onmousemove = updateBannerPosition;
    }
  }

  render() {
    // const {
    //   intl: { formatMessage }
    // } = this.props;

    return (
      <section className="about-us-banner" id="aboutBanner">
        {/* <Parallax
          blur={0}
          bgImage={"/images/about/about-banner.jpg"}
          bgImageAlt="the cat"
          strength={1000}
          contentClassName="parallax-content"
        >
          <div>
            <Zoom>
              <h2>{formatMessage(messages.title1)}</h2>
              <h3>
                <strong>OHMYHOTEL & CO</strong>
              </h3>
            </Zoom>
          </div>
        </Parallax> */}
        <div>
          <Zoom>
            <FormattedHTMLMessage {...messages.title1} />
            {/* <h2>{formatMessage(messages.title1)}</h2>
            <img src="/images/logo/name-white.svg" height="27" alt="" /> */}
          </Zoom>
        </div>
      </section>
    );
  }
}

export default injectIntl(AboutUsBanner);
