import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "PMS.intro.header",
    defaultMessage: "<h3>Property Management System</h3>"
  },
  titleCommon: {
    id: "PMS.intro.titleCommon",
    defaultMessage: "Intuitive Asset Management System, Just one."
  },
  contentCommon: {
    id: "PMS.intro.contentCommon",
    defaultMessage:
      "The OhmyhotelHotel & Co. PMS provides a complete operating environment for all room and lodging, such as hotels and residences, and for all management that takes place in the ancillary facilities."
  },
  title1: {
    id: "PMS.intro.title1",
    defaultMessage: "Efficiency"
  },
  content1: {
    id: "PMS.intro.content1",
    defaultMessage:
      "<li>Accessible with various devices without place/time restrictions</li><li>Secures transparent and efficient hotel management</li><li>Regular updates</li><li>Supports integrated management service (customer, booking, sales information) for each hotel chains.</li>"
  },
  title2: {
    id: "PMS.intro.title2",
    defaultMessage: "Cost Reduction"
  },
  content2: {
    id: "PMS.intro.content2",
    defaultMessage:
      "<li>Less initial introduction cost and operational cost via cloud service</li><li>Less operation/management/marketing cost via various report and performance management</li>"
  },
  title3: {
    id: "PMS.intro.title3",
    defaultMessage: "Compatibility"
  },
  content3: {
    id: "PMS.intro.content3",
    defaultMessage:
      "<li>Easy customizing via connection to other systems and various options</li>"
  }
});
class PMSIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="pms-intro-section">
        <Fade distance="2rem" bottom>
          <div className="section-header">
            <FormattedHTMLMessage {...messages.header} />
            <span className="header-line" />
          </div>
          <Container>
            <div className="section-content">
              <h6>{formatMessage(messages.titleCommon)}</h6>
              <FormattedHTMLMessage tagName="p" {...messages.contentCommon} />
              <div className="pms-images-system">
                <img
                  width="742"
                  src="/images/business/PMS/property-management-system.jpg"
                  alt=""
                />
              </div>
              <div className="content-center">
                <div>
                  <Row>
                    <Col>
                      <img src="/images/business/PMS/efficiency.svg" alt="" />
                      {formatMessage(messages.title1)}
                    </Col>
                    <Col>
                      <FormattedHTMLMessage
                        tagName="ul"
                        {...messages.content1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <img
                        src="/images/business/PMS/cost-reduction.svg"
                        alt=""
                      />
                      {formatMessage(messages.title2)}
                    </Col>
                    <Col>
                      <FormattedHTMLMessage
                        tagName="ul"
                        {...messages.content2}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <img
                        src="/images/business/PMS/compatibility.svg"
                        alt=""
                      />
                      {formatMessage(messages.title3)}
                    </Col>
                    <Col>
                      <FormattedHTMLMessage
                        tagName="ul"
                        {...messages.content3}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(PMSIntro);
