import React from "react";

import { injectIntl, defineMessages } from "react-intl";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { Link } from "react-router-dom";

import classnames from "classnames";
//import { GetPageActiveLv1 } from "../../../actions/GetPageActive";

import "./Header.scss";

const messages = defineMessages({
  item1: {
    id: "header.item1",
    defaultMessage: "Home"
  },
  item2: {
    id: "header.item2",
    defaultMessage: "About us"
  },
  item3: {
    id: "header.item3",
    defaultMessage: "Business"
  },
  item4: {
    id: "header.item4",
    defaultMessage: "Careers"
  },
  langEn: {
    id: "header.langEn",
    defaultMessage: "English"
  },
  langVi: {
    id: "header.langVi",
    defaultMessage: "Vietnamese"
  },
  langKo: {
    id: "header.langKo",
    defaultMessage: "Korean"
  },
  langJa: {
    id: "header.langJa",
    defaultMessage: "Japanese"
  },
  businessItem1: {
    id: "business.submenu.item1",
    defaultMessage: "OTA"
  },
  businessItem2: {
    id: "business.submenu.item2",
    defaultMessage: "Booking Engine"
  },
  businessItem3: {
    id: "business.submenu.item3",
    defaultMessage: "PMS"
  },
  businessItem4: {
    id: "business.submenu.item4",
    defaultMessage: "CMS"
  },
  businessItem5: {
    id: "business.submenu.item5",
    defaultMessage: "Hotel Management"
  },
  aboutUsItem1: {
    id: "aboutUs.submenu.item1",
    defaultMessage: "CEO Message"
  },
  aboutUsItem2: {
    id: "aboutUs.submenu.item2",
    defaultMessage: "Vision"
  },
  aboutUsItem3: {
    id: "aboutUs.submenu.item3",
    defaultMessage: "History"
  },
  aboutUsItem4: {
    id: "aboutUs.submenu.item4",
    defaultMessage: "CI"
  },
  aboutUsItem5: {
    id: "aboutUs.submenu.item5",
    defaultMessage: "Location"
  },
  careerItem1: {
    id: "career.submenu.item1",
    defaultMessage: "Desired Traits"
  },
  careerItem2: {
    id: "career.submenu.item2",
    defaultMessage: "Job Opportunities"
  }
});
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: JSON.parse(window.localStorage.getItem("__lang__")) || {
        name: "langJa",
        code: "ja"
      },
      //pageActive: GetPageActiveLv1(),

      isOpen: false,
      dropdownOpening: ""
    };
    this.toggle = this.toggle.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.openNav = this.openNav.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  closeNav() {
    document.body.classList.remove("disable-scroll");
    this.setState({
      isOpen: false
    });
  }
  openNav() {
    document.body.classList.add("disable-scroll");
    // document.body.className += " disable-scroll";
    this.setState({
      isOpen: true
    });
  }
  selectLanguage(lang) {
    this.setState({ lang: lang });
    window.localStorage.setItem("__lang__", JSON.stringify(lang));
    this.props.reload();
    document.body.classList.remove("disable-scroll");
  }

  openDropdown(dropdown) {
    if (
      document
        .getElementsByClassName("navbar-collapse")[0]
        .classList.contains("show")
    )
      return;

    this.setState({ dropdownOpening: dropdown });
  }
  closeDropdown() {
    if (
      document
        .getElementsByClassName("navbar-collapse")[0]
        .classList.contains("show")
    )
      return;
    this.setState({ dropdownOpening: "" });
  }
  toggleDropdown(dropdown) {
    this.state.dropdownOpening === dropdown
      ? this.setState({ dropdownOpening: "" })
      : this.setState({ dropdownOpening: dropdown });
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    const pageActive = window.location.pathname.split("/")[1];
    return (
      <div className="App-header">
        <Navbar light expand="md" className="container">
          <NavbarBrand tag={Link} to="/">
            <img src="/images/logo/logo.svg" alt="Ohmyhotel & CO" height="34" />
          </NavbarBrand>
          <NavbarToggler onClick={this.openNav} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <div className="close-collapse-nav" onClick={this.closeNav}>
              <span>
                <img src="/images/icons/close.svg" alt="Close" height="20" />
              </span>
            </div>
            <Nav className="ml-auto" navbar>
              <Dropdown
                nav
                inNavbar
                className={classnames({
                  active: pageActive === "about-us"
                })}
                // onMouseOver={e => this.onMouseEnter("dropdownAboutOpen")}
                // onMouseLeave={e => this.onMouseLeave("dropdownAboutOpen")}
                // toggle={e => this.toggleDropdown("dropdownAboutOpen")}
                onMouseOver={e => this.openDropdown("About")}
                onMouseLeave={e => this.closeDropdown()}
                toggle={e => {
                  this.toggleDropdown("About");
                }}
                //isOpen={this.state.dropdownAboutOpen}
                isOpen={this.state.dropdownOpening === "About"}
              >
                <DropdownToggle nav caret>
                  {formatMessage(messages.item2)}
                </DropdownToggle>
                <span className="header-line sort-line" />
                <DropdownMenu right>
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/about-us/ceo-message"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.aboutUsItem1)}
                  </NavLink>
                  {/* </DropdownItem> */}
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/about-us/vision"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.aboutUsItem2)}
                  </NavLink>
                  {/* </DropdownItem> */}
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/about-us/history"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.aboutUsItem3)}
                  </NavLink>
                  {/* </DropdownItem> */}
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/about-us/ci"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.aboutUsItem4)}
                  </NavLink>
                  {/* </DropdownItem> */}
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/about-us/location"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.aboutUsItem5)}
                  </NavLink>
                  {/* </DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                inNavbar
                className={classnames({
                  active: pageActive === "business"
                })}
                // onMouseOver={e => this.onMouseEnter("dropdownBusinessOpen")}
                // onMouseLeave={e => this.onMouseLeave("dropdownBusinessOpen")}
                // toggle={e => this.toggleDropdown("dropdownBusinessOpen")}
                onMouseOver={e => this.openDropdown("Business")}
                onMouseLeave={e => this.closeDropdown()}
                toggle={e => this.toggleDropdown("Business")}
                //isOpen={this.state.dropdownBusinessOpen}
                isOpen={this.state.dropdownOpening === "Business"}
              >
                <DropdownToggle nav caret>
                  {formatMessage(messages.item3)}
                </DropdownToggle>
                <span className="header-line sort-line" />
                <DropdownMenu right>
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/business/online-booking-engine"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.businessItem1)}
                  </NavLink>
                  {/* </DropdownItem> */}
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/business/hotel-web-design"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.businessItem2)}
                  </NavLink>
                  {/* </DropdownItem> */}
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/business/pms"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.businessItem3)}
                  </NavLink>
                  {/* </DropdownItem> */}
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/business/cms"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.businessItem4)}
                  </NavLink>
                  {/* </DropdownItem> */}
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  {/* <NavLink
                    tag={Link}
                    to="/business/hotel-management"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.businessItem5)}
                  </NavLink> */}
                  {/* </DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                inNavbar
                className={classnames({
                  active: pageActive === "careers"
                })}
                // onMouseOver={e => this.onMouseEnter("dropdownCareersOpen")}
                // onMouseLeave={e => this.onMouseLeave("dropdownCareersOpen")}
                // toggle={e => this.toggleDropdown("dropdownCareersOpen")}
                onMouseOver={e => this.openDropdown("Careers")}
                onMouseLeave={e => this.closeDropdown()}
                toggle={e => this.toggleDropdown("Careers")}
                // isOpen={this.state.dropdownCareersOpen}
                isOpen={this.state.dropdownOpening === "Careers"}
              >
                <DropdownToggle nav caret>
                  {formatMessage(messages.item4)}
                </DropdownToggle>
                <span className="header-line sort-line" />
                <DropdownMenu right>
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/careers/desired-traits"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.careerItem1)}
                  </NavLink>
                  {/* </DropdownItem> */}
                  {/* <DropdownItem toggle={true} onClick={this.toggle}> */}
                  <NavLink
                    tag={Link}
                    to="/careers/job-opportunities"
                    onClick={e => {
                      this.closeDropdown();
                      this.closeNav();
                    }}
                  >
                    {formatMessage(messages.careerItem2)}
                  </NavLink>
                  {/* </DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                inNavbar
                className="languages"
                // onMouseOver={e => this.onMouseEnter("dropdownLangOpen")}
                // onMouseLeave={e => this.onMouseLeave("dropdownLangOpen")}
                // toggle={e => this.toggleDropdown("dropdownLangOpen")}
                onMouseOver={e => this.openDropdown("Lang")}
                onMouseLeave={e => this.closeDropdown("Lang")}
                toggle={e => this.toggleDropdown("Lang")}
                // isOpen={this.state.dropdownLangOpen}
                isOpen={this.state.dropdownOpening === "Lang"}
              >
                <DropdownToggle nav caret>
                  <span className="lang-flag">
                    <img
                      src={"/images/flags/" + this.state.lang.code + ".svg"}
                      alt=""
                    />
                  </span>
                  <span>{formatMessage(messages[this.state.lang.name])}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <div
                    onClick={() =>
                      this.selectLanguage({ name: "langJa", code: "ja" })
                    }
                  >
                    <span className="lang-flag">
                      <img src={"/images/flags/ja.svg"} alt="" />
                    </span>
                    <span>{formatMessage(messages.langJa)}</span>
                  </div>
                  <div
                    onClick={() =>
                      this.selectLanguage({ name: "langEn", code: "en" })
                    }
                  >
                    <span className="lang-flag">
                      <img src={"/images/flags/en.svg"} alt="" />
                    </span>
                    <span>{formatMessage(messages.langEn)}</span>
                  </div>
                  <div
                    onClick={() =>
                      this.selectLanguage({ name: "langVi", code: "vi" })
                    }
                  >
                    <span className="lang-flag">
                      <img src={"/images/flags/vi.svg"} alt="" />
                    </span>
                    <span>{formatMessage(messages.langVi)}</span>
                  </div>
                  <div
                    onClick={() =>
                      this.selectLanguage({ name: "langKo", code: "ko" })
                    }
                  >
                    <span className="lang-flag">
                      <img src={"/images/flags/ko.svg"} alt="" />
                    </span>
                    <span>{formatMessage(messages.langKo)}</span>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default injectIntl(Header);
