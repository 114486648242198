import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  content1: {
    id: "privacy.content.content1",
    defaultMessage:
      "Ohmyhotel & Co has established a strict personal information protection policy to make sure all employees acknowledge the importance of protecting our customers’ personal information and take all necessary precautions."
  },
  title2: {
    id: "privacy.content.title2",
    defaultMessage: "Personal information Management"
  },
  content2: {
    id: "privacy.content.content2",
    defaultMessage:
      "We keep all personal information up-to-date to maintain accuracy, while implementing security systems, data management systems, employee training programs and other required safety measures to prevent any unauthorized access, loss, damage and/or leakage of personal information."
  },
  title3: {
    id: "privacy.content.title3",
    defaultMessage: "Usage of personal information"
  },
  content3: {
    id: "privacy.content.content3",
    defaultMessage:
      "Gathered personal information may to used to contact customers to provide assistance or reply to any questions and/or complaints."
  },
  title4: {
    id: "privacy.content.title4",
    defaultMessage: "Non-disclosure of personal information to third parties"
  },
  content4: {
    id: "privacy.content.content4",
    defaultMessage:
      "We guarantee all necessary precautions will be taken to protect any personal information gathered and will not disclose such information to any third parties, unless:" +
      "<ul><li>* Prior consent is give</li>" +
      "<li>* Such information is necessary to provide a requested service, which is contracted to third party ; and</li>" +
      "<li>* Disclosure is required by applicable laws and regulations</li></ul>"
  },
  title5: {
    id: "privacy.content.title5",
    defaultMessage: "Personal information Management"
  },
  content5: {
    id: "privacy.content.content5",
    defaultMessage:
      "We keep all personal information up-to-date to maintain accuracy, while implementing security systems, data management systems, employee training programs and other required safety measures to prevent any unauthorized access, loss, damage and/or leakage of personal information."
  },
  title6: {
    id: "privacy.content.title6",
    defaultMessage: "Personal information safeguards"
  },
  content6: {
    id: "privacy.content.content6",
    defaultMessage:
      "We have implemented safeguards to ensure accuracy and safety of personal information"
  },
  title7: {
    id: "privacy.content.title7",
    defaultMessage: "Personal inquiry"
  },
  content7: {
    id: "privacy.content.content7",
    defaultMessage:
      "Any access, change and/or deletion of personal information will require verification of identity."
  },
  title8: {
    id: "privacy.content.title8",
    defaultMessage:
      "Compliance with laws and regulations and revision of policy"
  },
  content8: {
    id: "privacy.content.content8",
    defaultMessage:
      "We guarantee strict compliance to all laws and regulations regarding personal information protection practices in Japan, and revision of our Privacy Policy when necessar."
  },
  title9: {
    id: "privacy.content.title9",
    defaultMessage: "FAQ and Complaints"
  },
  content9: {
    id: "privacy.content.content9",
    defaultMessage:
      "Pleases direct any inquiries, claims and complaints to the contacts below:"
  },
  contact: {
    id: "privacy.content.contact",
    defaultMessage:
      "<strong>Ohmyhotel & Co</strong><br />" +
      "Address : 〒550-0006　9F, Erfolg Awaza Bldg, 1-6-5, Enokojima, Nishi-ku, Osaka-shi, Osaka, Japan<br />" +
      "Tel : +81-6-6940-7949"
  }
});

class PrivacyContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="privacy-content-section">
        <Fade distance="2rem" bottom>
          <Container className="section-content align-left">
            <p>{formatMessage(messages.content1)}</p>

            <h6 className="p-title">{formatMessage(messages.title2)}</h6>
            <p>{formatMessage(messages.content2)}</p>

            <h6 className="p-title">{formatMessage(messages.title3)}</h6>
            <p>{formatMessage(messages.content3)}</p>

            <h6 className="p-title">{formatMessage(messages.title4)}</h6>
            <FormattedHTMLMessage tagName="p" {...messages.content4} />

            {/* <h6 className="p-title">{formatMessage(messages.title5)}</h6>
          <p>{formatMessage(messages.content5)}</p> */}

            <h6 className="p-title">{formatMessage(messages.title6)}</h6>
            <p>{formatMessage(messages.content6)}</p>

            <h6 className="p-title">{formatMessage(messages.title7)}</h6>
            <p>{formatMessage(messages.content7)}</p>

            <h6 className="p-title">{formatMessage(messages.title8)}</h6>
            <p>{formatMessage(messages.content8)}</p>

            <h6 className="p-title">{formatMessage(messages.title9)}</h6>
            <p>{formatMessage(messages.content9)}</p>
            <p className="contact">
              <FormattedHTMLMessage {...messages.contact} />
            </p>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(PrivacyContent);
