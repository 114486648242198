import React, { Component } from "react";
import AppRouter from "../../../routers/AppRouter";
import Header from "../Header";
import Footer from "../Footer";

import "./App.scss";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header reload={this.props.reload} />
        <div className="app-content">
          <AppRouter />
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
