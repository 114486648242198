import React, { Component } from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import jaLocaleData from "react-intl/locale-data/ja";
import viLocaleData from "react-intl/locale-data/vi";
import koLocaleData from "react-intl/locale-data/ko";
import translations from "./i18n/locales";
import App from "./layout/components/App";
import { Router } from "react-router-dom";
import history from "./helpers/history";

addLocaleData([...jaLocaleData, ...viLocaleData, ...koLocaleData]);
class AppWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeLang: false
    };
  }
  reload = () => {
    this.setState({ changeLang: !this.state.changeLang });
  };

  render() {
    // get locale from localStorage
    const locale = window.localStorage.getItem("__lang__")
      ? JSON.parse(window.localStorage.getItem("__lang__")).code
      : "ja";
    document.documentElement.lang = locale;
    const messages = translations[locale];
    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <Router history={history}>
          <App reload={this.reload} />
        </Router>
      </IntlProvider>
    );
  }
}

export default AppWrapper;
