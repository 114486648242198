import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "CMS.intro.header",
    defaultMessage: "<h3>Channel Manager System</h3>"
  },
  titleCommon: {
    id: "CMS.intro.titleCommon",
    defaultMessage:
      "A lot of channels, lots and lots of channels.<br/>Try to manage it more efficiently"
  },
  contentCommon: {
    id: "CMS.intro.contentCommon",
    defaultMessage:
      "Easily manage multiple online reservation channels by connecting them to a single system.<br/>Ohmyhotel&co EZ-CMS increase the efficiency of your work and maximize room sales."
  }
});
class CMSIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const {
    //   intl: { formatMessage }
    // } = this.props;
    return (
      <section className="cms-intro-section">
        <Fade distance="2rem" bottom>
          <div className="section-header">
              <FormattedHTMLMessage {...messages.header} />
              <span className="header-line" />
          </div>
          <Container>
            <div className="section-content">
              <FormattedHTMLMessage tagName="h6" {...messages.titleCommon} />
              <FormattedHTMLMessage tagName="p" {...messages.contentCommon} />
              <div className="world-map-bg">
                <img src="/images/business/CMS/world-map.png" alt="" />
                  <picture>
                    <source media='(max-width: 767.98px)'
                            srcSet='/images/business/CMS/m-channel-manager-system.svg' />
                    <img src='/images/business/CMS/channel-manager-system.svg' className="cms-img" alt="" />
                  </picture>
                  <picture>
                    <source media='(max-width: 767.98px)'
                            srcSet='/images/business/CMS/m-channel-manager-system-jp.svg' />
                    <img src='/images/business/CMS/channel-manager-system-jp.svg' className="cms-ja-img" alt="" />
                  </picture>
              </div>
            </div>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(CMSIntro);
