import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import { Container } from "reactstrap";
import { Fade } from "react-reveal";

import "./JobOpportunities.scss";

const messages = defineMessages({
  header: {
    id: "career.JobOpportunities.header",
    defaultMessage: "Job Opportunities"
  },
  title: {
    id: "career.JobOpportunities.title",
    defaultMessage: "There are no departments currently employed."
  }
});

class JobOpportunities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="job-opportunities-section">
        <Fade distance="2rem" bottom>
          <div className="section-header align-center">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </div>
          <Container className="section-content">
            {/* <h4 className=" blur-text">{formatMessage(messages.title)}</h4> */}
            <div className="job-list">
              {/* <div className="job-item">
                <div>
                  <span>
                    <img src="/images/flags/vi.svg" alt="vi" height="19" />
                    Vietnam office
                  </span>
                </div>
                <div>
                  <strong>Sales Manager</strong>
                </div>
                <div>3/12-4/12</div>
                <div>
                  <a
                    href="https://www.vietnamworks.com/sales-manager-992-1072305-jd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More Details <img src="/images/icons/chevron.svg" alt=">" />
                  </a>
                </div>
              </div>
              <div className="job-item">
                <div>
                  <span>
                    <img src="/images/flags/vi.svg" alt="vi" height="19" />
                    Vietnam office
                  </span>
                </div>
                <div>
                  <strong>Accounting Manager</strong>
                </div>
                <div>3/12-4/12</div>
                <div>
                  <a
                    href="https://www.vietnamworks.com/accounting-manager-133-1072298-jd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More Details <img src="/images/icons/chevron.svg" alt=">" />
                  </a>
                </div>
              </div> */}
              <div className="job-item">
                <div>
                  <span>
                    <img src="/images/flags/vi.svg" alt="vi" height="19" />
                    Vietnam office
                  </span>
                </div>
                <div>
                  <strong>Front end developer</strong>
                </div>
                <div>5/2-5/31</div>
                <div>
                  <a
                    href="https://itviec.com/it-jobs/front-end-developer-ohmylab-vn-company-limited-4516"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More Details <img src="/images/icons/chevron.svg" alt=">" />
                  </a>
                </div>
              </div>
              <div className="job-item">
                <div>
                  <span>
                    <img src="/images/flags/vi.svg" alt="vi" height="19" />
                    Vietnam office
                  </span>
                </div>
                <div>
                  <strong>Full stack developer</strong>
                </div>
                <div>5/2-5/31</div>
                <div>
                  <a
                    href="https://itviec.com/it-jobs/full-stack-developer-net-c-all-level-ohmylab-vn-company-limited-5942"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More Details <img src="/images/icons/chevron.svg" alt=">" />
                  </a>
                </div>
              </div>
            </div>
            <img src="/images/career/job-opportunities.svg" alt="" />
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(JobOpportunities);
