import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "OTA.lowerCosts.header",
    defaultMessage:
      "Lower costs, increase revenue <br/>From the smart management system"
  },
  commonContent: {
    id: "OTA.lowerCosts.commonContent",
    defaultMessage:
      "The administrator's page of the Ohmyhotel provides quick and easy management from hotel registration to reservation, and is an integrated management system for business personnel such as setting up members and suppliers."
  },
  titte1: {
    id: "OTA.lowerCosts.titte1",
    defaultMessage: "Room/reservation management"
  },
  titte2: {
    id: "OTA.lowerCosts.titte2",
    defaultMessage: "User-Centric Menu Configuration"
  },
  titte3: {
    id: "OTA.lowerCosts.titte3",
    defaultMessage: "System Intergration"
  },
  titte4: {
    id: "OTA.lowerCosts.titte4",
    defaultMessage: "Maintenance"
  },
  content1: {
    id: "OTA.lowerCosts.content1",
    defaultMessage:
      "<li>Accommodation, room management </li><li>Member management</li><li>Rate/stock management</li><li>Options management</li><li>Sales suspension management</li>"
  },
  content2: {
    id: "OTA.lowerCosts.content2",
    defaultMessage:
      "<li>User-friendly interface </li><li>Easy hotel registration, high quality image exposure </li><li>Easy and fast supplier management</li><li>Simultaneous payment and manual booking</li>"
  },
  content3: {
    id: "OTA.lowerCosts.content3",
    defaultMessage:
      "<li>Works with the Ohmyhotel System (Ez-GET Series)</li><li>Convenient reservation management through PMS intergration</li><li>Inventory/fee management through CMS intergration</li><li>Interworking with the Ohmyhotel Alliance Network</li>"
  },
  content4: {
    id: "OTA.lowerCosts.content4",
    defaultMessage:
      "<li>Optimizing room search, online payment/ confirmation of payment, etc.</li><li>Continuous monitoring for website stabilization </li>"
  }
});

class OTALowerCosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="ota-lowercost-section">
        <Fade distance="2rem" bottom>
          <div className="section-header align-center">
            <FormattedHTMLMessage tagName="h3" {...messages.header} />
            <span className="header-line" />
          </div>
          <Container className="section-content">
            <FormattedHTMLMessage tagName="p" {...messages.commonContent} />

            <Row>
              <Col
                md={{ size: 6, order: 1 }}
                sm={{ size: 12, order: 2 }}
                xs={{ size: 12, order: 2 }}
                className="align-left"
              >
                <div>
                  <h6 className="p-title">{formatMessage(messages.titte1)}</h6>
                  <FormattedHTMLMessage tagName="ul" {...messages.content1} />
                </div>
                <div>
                  <h6 className="p-title">{formatMessage(messages.titte2)}</h6>
                  <FormattedHTMLMessage tagName="ul" {...messages.content2} />
                </div>
              </Col>
              <Col
                md={{ size: 6, order: 2 }}
                sm={{ size: 12, order: 1 }}
                xs={{ size: 12, order: 1 }}
              >
                <img src="/images/business/OTA/management-system.jpg" alt="" />
              </Col>
              <Col 
                md={{ size: 6, order: 3 }} 
                sm={{ size: 12, order: 3 }}
                xs={{ size: 12, order: 3 }}
                >
                <img
                  src="/images/business/OTA/management-system-1.svg"
                  alt=""
                />
              </Col>
              <Col
                md={{ size: 6, order: 4 }}
                sm={{ size: 12, order: 4 }}
                xs={{ size: 12, order: 4 }}
                className="align-left"
              >
                <div>
                  <h6 className="p-title">{formatMessage(messages.titte3)}</h6>
                  <FormattedHTMLMessage tagName="ul" {...messages.content3} />
                </div>
                <div>
                  <h6 className="p-title">{formatMessage(messages.titte4)}</h6>
                  <FormattedHTMLMessage tagName="ul" {...messages.content4} />
                </div>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(OTALowerCosts);
