import React from "react";
import CIIntro from "./CIIntro";
import ColorSystem from "./ColorSystem";
import CorporateSignature from "./CorporateSignature";

import "./CI.scss";

class CI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="CI-page">
        <CIIntro />
        <ColorSystem />
        <CorporateSignature />
      </div>
    );
  }
}

export default CI;
