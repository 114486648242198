import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "CI.CIIntro.header",
    defaultMessage: "CI"
  },
  title: {
    id: "CI.CIIntro.title",
    defaultMessage: "Loved and Reliable Company, Ohmyhotel & Co"
  },
  content: {
    id: "CI.CIIntro.content",
    defaultMessage:
      "CI of Ohmyhotel & Co is the design which expresses creative ideas and active thinking method in a visual language. CI also symbolizes the symbol mark of orange with a pleasant scent. CI is the symbol of customer-centered management communicating with partners anytime and anywhere."
  },
  AIdownload: {
    id: "CI.CIIntro.AIdownload",
    defaultMessage: "AI Download"
  },
  JPGdownload: {
    id: "CI.CIIntro.JPGdownload",
    defaultMessage: "JPG Download"
  }
});
class CIIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <section className="ci-intro-section">
        <Fade distance="2rem" bottom>
          <Container className="section-header">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </Container>
          <div className="section-content">
            <Container>
              <h6 className="p-title">
                <FormattedHTMLMessage {...messages.title} />
              </h6>
              <FormattedHTMLMessage tagName="p" {...messages.content} />
            </Container>

            <div className="full-width-image" />

            <div className="content-center">
              <a
                href="/images/about/ci_ohmyhotelco_ai.zip"
                download="ci_ohmyhotelco_ai"
                className="btn btn-primary"
              >
                <img src="/images/about/download.svg" alt="" width="18" />
                <span>{formatMessage(messages.AIdownload)}</span>
              </a>
              <a
                href="/images/about/ci_ohmyhotelco.zip"
                download="ci_ohmyhotelco"
                className="btn btn-primary"
              >
                <img src="/images/about/download.svg" alt="" width="18" />
                <span>{formatMessage(messages.JPGdownload)}</span>
              </a>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(CIIntro);
