import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Zoom } from "react-reveal";

const messages = defineMessages({
  title1: {
    id: "privacy.banner.title1",
    defaultMessage: "Privacy Policy"
  }
});

class PrivacyBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    var movementStrength = 50;
    var height =
      movementStrength / document.getElementById("privacyBanner").clientWidth;
    var width =
      movementStrength / document.getElementById("privacyBanner").clientWidth;
    function updateBannerPosition(e) {
      var pageX =
        e.pageX - document.getElementById("privacyBanner").clientWidth / 2;
      var pageY =
        e.pageY - document.getElementById("privacyBanner").clientHeight / 2;
      var newvalueX = width * pageX * -1;
      var newvalueY = height * pageY * -1;

      document.getElementById("privacyBanner").style.backgroundPosition =
        "10% -5% , 90% 115%,  calc( 50% + " +
        newvalueX +
        "px) calc( 50% + " +
        newvalueY +
        "px)";
    }
    if (!(!!window.MSInputMethodContext && !!document.documentMode)) {
      document.getElementById(
        "privacyBanner"
      ).onmousemove = updateBannerPosition;
    }
  }

  render() {
    // const {
    //   intl: { formatMessage }
    // } = this.props;

    return (
      <section className="privacy-banner" id="privacyBanner">
        {/* <Parallax
          blur={0}
          bgImage={"/images/draft/carousel3.jpg"}
          bgImageAlt="the cat"
          strength={500}
          contentClassName="parallax-content"
        > */}
        <div>
          <Zoom>
            <FormattedHTMLMessage tagName="h2" {...messages.title1} />
          </Zoom>
        </div>
        {/* </Parallax> */}
      </section>
    );
  }
}

export default injectIntl(PrivacyBanner);
