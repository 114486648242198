import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

import "./Vision.scss";

const messages = defineMessages({
  header: {
    id: "aboutUs.vision.header",
    defaultMessage: "Vision"
  },
  imgText: {
    id: "aboutUs.vision.imgText",
    defaultMessage: "Your Friend and Confidant"
  },
  title1: {
    id: "aboutUs.vision.title1",
    defaultMessage: "Society"
  },
  content1: {
    id: "aboutUs.vision.content1",
    defaultMessage:
      "We are committed to social responsibilities to the community."
  },
  title2: {
    id: "aboutUs.vision.title2",
    defaultMessage: "Partner"
  },
  content2: {
    id: "aboutUs.vision.content2",
    defaultMessage:
      "We understand the need for coexistence with our partners and strive to maximize mutual interests."
  },
  title3: {
    id: "aboutUs.vision.title3",
    defaultMessage: "Investor"
  },
  content3: {
    id: "aboutUs.vision.content3",
    defaultMessage:
      "We guarantee transparence. We will present honest and open visions and fully disclose our operations, while meeting the expectations of our investors by staying true to our path and maximizing their investments."
  },
  title4: {
    id: "aboutUs.vision.title4",
    defaultMessage: "Customer"
  },
  content4: {
    id: "aboutUs.vision.content4",
    defaultMessage:
      "Every member of the staff places top priority on customers satisfaction, and strives to provide services worthy of our customers’ love and trust."
  },
  title5: {
    id: "aboutUs.vision.title5",
    defaultMessage: "Employee"
  },
  content5: {
    id: "aboutUs.vision.content5",
    defaultMessage:
      "We provide employee benefits and hold company events to make sure our staff members are comfortable and happy at the office."
  }
});
class Vision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <div>
        <section className="vision-section">
          <Fade distance="2rem" bottom>
            <Container>
              <div className="section-header">
                <h3>{formatMessage(messages.header)}</h3>
                <span className="header-line" />
              </div>
              <Row className="section-content">
                <Col lg="6">
                  <div>
                    <img src="/images/about/vision.svg" alt="" />
                    <FormattedHTMLMessage tagName="h3" {...messages.imgText} />
                  </div>
                </Col>
                <Col lg="6" className="align-left">
                  {/* <dl className="align-left">
                  <dt>{formatMessage(messages.title1)}</dt>
                  <dd>{formatMessage(messages.content1)}</dd>
                  <dt>{formatMessage(messages.title2)}</dt>
                  <dd>{formatMessage(messages.content2)}</dd>
                  <dt>{formatMessage(messages.title3)}</dt>
                  <dd>{formatMessage(messages.content3)}</dd>
                  <dt>{formatMessage(messages.title4)}</dt>
                  <dd>{formatMessage(messages.content4)}</dd>
                  <dt>{formatMessage(messages.title5)}</dt>
                  <dd>{formatMessage(messages.content5)}</dd>
                </dl> */}

                  <Row>
                    <Col>
                      <h6 className="p-title">
                        {formatMessage(messages.title1)}
                      </h6>
                    </Col>
                    <Col>{formatMessage(messages.content1)}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6 className="p-title">
                        {formatMessage(messages.title2)}
                      </h6>
                    </Col>
                    <Col>{formatMessage(messages.content2)}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6 className="p-title">
                        {formatMessage(messages.title3)}
                      </h6>
                    </Col>
                    <Col>
                      <FormattedHTMLMessage {...messages.content3} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6 className="p-title">
                        {formatMessage(messages.title4)}
                      </h6>
                    </Col>
                    <Col>{formatMessage(messages.content4)}</Col>
                  </Row>
                  <Row className="last-row">
                    <Col>
                      <h6 className="p-title">
                        {formatMessage(messages.title5)}
                      </h6>
                    </Col>
                    <Col>{formatMessage(messages.content5)}</Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fade>
        </section>
      </div>
    );
  }
}

export default injectIntl(Vision);
