import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import CareersRouter from "../../../routers/CareersRouter";
import SubMenu from "../../components/SubMenu";
import CareerBanner from "./CareerBanner";

import "./CareersPage.scss";

const messages = defineMessages({
  item1: {
    id: "career.submenu.item1",
    defaultMessage: "Desired Traits"
  },
  item2: {
    id: "career.submenu.item2",
    defaultMessage: "Job Opportunities"
  }
});

class CareersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
  }

  render() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const {
      intl: { formatMessage }
    } = this.props;
    const { match } = this.props;
    const items = [
      {
        path: "desired-traits",
        name: formatMessage(messages.item1)
      },
      {
        path: "job-opportunities",
        name: formatMessage(messages.item2)
      }
    ];
    return (
      <div>
        <CareerBanner />
        <SubMenu items={items} match={match} />
        <CareersRouter url={match.url} />
      </div>
    );
  }
}

export default injectIntl(CareersPage);
