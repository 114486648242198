import React from "react";
import ReactDOM from "react-dom";
import "babel-polyfill";
import "bootstrap/dist/css/bootstrap.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/_langs.scss";
import "./index.css";
import AppWrapper from "./AppWrapper";
import * as serviceWorker from "./serviceWorker";
import "./styles/_font-face.scss";
import "./styles/_variables.scss";
import "./styles/_media.scss";

ReactDOM.render(<AppWrapper />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
