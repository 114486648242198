import React from "react";
import BEWebDesign from "./BEWebDesign";
import BEBookingEngine from "./BEBookingEngine";
import BEAskForEmail from "./BEAskForEmail";

import "./BE.scss";

class BE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   console.log("GrandChild did mount.");
  //   let target = window.location.hash;
  //   if (!target) return;
  //   //setTimeout(function() {
  //   let objControl = document.getElementById(window.location.hash.substring(1));
  //   objControl.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start"
  //   });
  //   // window.scrollBy({
  //   //   top: -100,
  //   //   behavior: "smooth"
  //   // });
  //   //window.scrollBy(0, -100);
  //   //}, 500);
  // }
  // scrollTo() {
  // let target = window.location.hash;
  // if (!target) return;
  // setTimeout(function() {
  //   let objControl = document.getElementById(
  //     window.location.hash.substring(1)
  //   );
  //   objControl.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start"
  //   });
  //   window.scrollBy({
  //     top: -100,
  //     behavior: "smooth"
  //   });
  //   // window.scrollBy(0, -100);
  // }, 500);
  // setTimeout(() => {
  //   window.scrollBy(0, -100);
  // }, 2000);
  // }
  render() {
    return (
      <div>
        <BEWebDesign />
        <BEBookingEngine />
        <BEAskForEmail />
      </div>
    );
  }
}

export default BE;
