import React from "react";
import CEOMessage from "../layout/pages/AboutUsPage/CEOMessage";
import Vision from "../layout/pages/AboutUsPage/Vision";
import History from "../layout/pages/AboutUsPage/History";
import CI from "../layout/pages/AboutUsPage/CI";
import Location from "../layout/pages/AboutUsPage/Location";
import { Route, Switch } from "react-router-dom";

class AboutUsRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const url = this.props.url;
    return (
      <Switch>
        <Route exact path={`${url}/ceo-message`} component={CEOMessage} />
        <Route exact path={`${url}/vision`} component={Vision} />
        <Route exact path={`${url}/history`} component={History} />
        <Route exact path={`${url}/ci`} component={CI} />
        <Route exact path={`${url}/location`} component={Location} />
      </Switch>
    );
  }
}

export default AboutUsRouter;
