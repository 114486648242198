import React from "react";
import OTAChooseAccom from "./OTAChooseAccom";
import OTALowerCosts from "./OTALowerCosts";
import OTAPartner from "./OTAPartner";

import "./OTA.scss";

class OTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <OTAChooseAccom />
        <OTALowerCosts />
        <OTAPartner />
      </div>
    );
  }
}

export default OTA;
