import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container } from "reactstrap";
import { Fade } from "react-reveal";

import "./CEOMessage.scss";

const messages = defineMessages({
  header: {
    id: "aboutUs.ceoMesage.header",
    defaultMessage: "CEO Message"
  },
  thankYou: {
    id: "aboutUs.ceoMesage.thankYou",
    defaultMessage: "Thank you for visiting Ohmyhotel & Co"
  },
  content: {
    id: "aboutUs.ceoMesage.content",
    defaultMessage:
      "<p>After the start of tourism business in 1997 through Bico, we have been trying our best to provide a long-cherished pleasant travel. We accomplished continuous growth by focusing on flight and hotel booking and we developed the online booking system to provide an integrated domestic and overseas travel service.</p>" +
      "<p>Now, Ohmyhotel & Co is steping forward from system development and we are focusing on accomplishing a significant result in hotel solution field such as “System, marketing, and optional service” necessary for customer satisfaction.</p>" +
      "<p>Based on our global networks and sales know-how, we will take a leap toward “Software publishing company” for overseas marketing, finding new partner companies, and providing technology infrastructure.</p>" +
      "<p>Our Ohmyhotel & Co promises to become a loved and reliable company based on our approaching services.</p>"
  },
  ceoSign: {
    id: "aboutUs.ceoMesage.ceoSign",
    defaultMessage: "CEO Jackie Lee"
  }
});
class CEOMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <div>
        <section className="ceo-section">
          <Fade distance="2rem" bottom>
            <Container>
              <div className="section-header">
                <h3>{formatMessage(messages.header)}</h3>
                <span className="header-line" />
              </div>
              <div className="section-content align-left">
                <div>
                  <h4 className="quote">{formatMessage(messages.thankYou)}</h4>
                  <div>
                    <FormattedHTMLMessage tagName="div" {...messages.content} />
                  </div>
                  <div className="text-center text-md-right">
                    <span>{formatMessage(messages.ceoSign)}</span>
                    <img src="/images/about/sign.svg" alt="" />
                  </div>
                </div>
                <div className="ceo-img">
                  <picture>
                    <source media='(max-width: 767.98px)'
                            srcSet='/images/about/m-img_ceo.png' />
                    <img src='/images/about/img_ceo.png' alt="" />
                  </picture>
                </div>
              </div>
            </Container>
          </Fade>
        </section>
      </div>
    );
  }
}

export default injectIntl(CEOMessage);
