import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import AboutUsRouter from "../../../routers/AboutUsRouter";
import SubMenu from "../../components/SubMenu";
import AboutUsBanner from "./AboutUsBanner";

import "./AboutUsPage.scss";

const messages = defineMessages({
  item1: {
    id: "aboutUs.submenu.item1",
    defaultMessage: "CEO Message"
  },
  item2: {
    id: "aboutUs.submenu.item2",
    defaultMessage: "Vision"
  },
  item3: {
    id: "aboutUs.submenu.item3",
    defaultMessage: "History"
  },
  item4: {
    id: "aboutUs.submenu.item4",
    defaultMessage: "CI"
  },
  item5: {
    id: "aboutUs.submenu.item5",
    defaultMessage: "Location"
  }
});
class AboutUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const {
      intl: { formatMessage }
    } = this.props;
    const { match } = this.props;
    const items = [
      {
        path: "ceo-message",
        name: formatMessage(messages.item1)
      },
      {
        path: "vision",
        name: formatMessage(messages.item2)
      },
      {
        path: "history",
        name: formatMessage(messages.item3)
      },
      {
        path: "ci",
        name: formatMessage(messages.item4)
      },
      {
        path: "location",
        name: formatMessage(messages.item5)
      }
    ];

    return (
      <div>
        <AboutUsBanner />
        <SubMenu items={items} match={match} />
        <AboutUsRouter url={match.url} />
      </div>
    );
  }
}

export default injectIntl(AboutUsPage);
