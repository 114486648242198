import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "CI.CorporateSignature.header",
    defaultMessage: "Corporate Signature"
  },
  content: {
    id: "CI.CorporateSignature.content",
    defaultMessage:
      "Signature is the most reasonable and organized combination of the basic symbols and logos – in other words, it is a formal representation of the company. This is the most straightforward method of representing the company’s image. Thus, it is finetuned to accordance to the balance of each element. Therefore, it should only be used in its specified perimeters."
  }
});
class CorporateSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <section className="corporate-signature-section">
        <Fade distance="2rem" bottom>
          <Container className="section-header">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </Container>
          <div className="section-content">
            <Container>
              <FormattedHTMLMessage tagName="p" {...messages.content} />
            </Container>
            <div className="full-width-image img-1" />
            <div className="full-width-image img-2" />
            <div className="full-width-image img-3" />
          </div>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(CorporateSignature);
