import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import { Container, Row, Col, Card } from "reactstrap";
import { Fade } from "react-reveal";

import "./DesiredTraits.scss";

const messages = defineMessages({
  header: {
    id: "career.desiredTraits.header",
    defaultMessage: "Desired Traits"
  },
  title1: {
    id: "career.desiredTraits.title1",
    defaultMessage: "Adaptability"
  },
  content1: {
    id: "career.desiredTraits.content1",
    defaultMessage:
      "People who understands rapid trend changes and adapts quickly"
  },
  title2: {
    id: "career.desiredTraits.title2",
    defaultMessage: "Health"
  },
  content2: {
    id: "career.desiredTraits.content2",
    defaultMessage:
      "People who is in good physical and mental health and strives for fitness"
  },
  title3: {
    id: "career.desiredTraits.title3",
    defaultMessage: "Vision Design"
  },
  content3: {
    id: "career.desiredTraits.content3",
    defaultMessage:
      "People who adopts the company’s vision as their own and designs their life goals around it"
  },
  title4: {
    id: "career.desiredTraits.title4",
    defaultMessage: "Confidence"
  },
  content4: {
    id: "career.desiredTraits.content4",
    defaultMessage:
      "People who take on challenges with optimism, and pride in the company and themselves"
  },
  title5: {
    id: "career.desiredTraits.title5",
    defaultMessage: "People Skills"
  },
  content5: {
    id: "career.desiredTraits.content5",
    defaultMessage:
      "People who has basic manners and values harmony and consideration between people"
  },
  title6: {
    id: "career.desiredTraits.title6",
    defaultMessage: "Self-development"
  },
  content6: {
    id: "career.desiredTraits.content6",
    defaultMessage:
      "Person who is constantly striving to acquire professional knowledge and cultivate to become the best professional in the industry."
  }
});

class DesiredTraits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="desired-traits-section">
        <Fade distance="2rem" bottom>
          <div className="section-header align-center">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </div>
          <Container className="section-content">
            <Row>
              <Col lg="4" md="6">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/career/adaptability.svg" alt="" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title1)}</h6>
                  <p>{formatMessage(messages.content1)}</p>
                </Card>
              </Col>
              <Col lg="4" md="6">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/career/health.svg" alt="" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title2)}</h6>
                  <p>{formatMessage(messages.content2)}</p>
                </Card>
              </Col>
              <Col lg="4" md="6">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/career/vision-design.svg" alt="" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title3)}</h6>
                  <p>{formatMessage(messages.content3)}</p>
                </Card>
              </Col>
              <Col lg="4" md="6">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/career/confidence.svg" alt="" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title4)}</h6>
                  <p>{formatMessage(messages.content4)}</p>
                </Card>
              </Col>
              <Col lg="4" md="6">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/career/people-skills.svg" alt="" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title5)}</h6>
                  <p>{formatMessage(messages.content5)}</p>
                </Card>
              </Col>
              <Col lg="4" md="6">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/career/self-development.svg" alt="" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title6)}</h6>
                  <p>{formatMessage(messages.content6)}</p>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(DesiredTraits);
