import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Parallax } from "react-parallax";
import Slider from "react-slick";
import { Fade } from "react-reveal";

const messages = defineMessages({
  companyName: {
    id: "home.banner.companyName",
    defaultMessage: "OHMYHOTEL & CO "
  },
  title1: {
    id: "home.banner.title1",
    defaultMessage: "Only from beginning to end."
  },
  subtitle1: {
    id: "home.banner.subtitle1",
    defaultMessage:
      "<h2>It's only one</h2><h2><img className='company-name-img' src='/images/logo/name-white.svg' height='27' alt=''/></h2>"
  },
  title2: {
    id: "home.banner.title2",
    defaultMessage:
      "<h2><img className='company-name-img' src='/images/logo/name-white.svg' height='30' alt=''/></h2>"
  },
  subtitle2: {
    id: "home.banner.subtitle2",
    defaultMessage: "<h1>Striving for Progress & Evolution</h1>"
  }
});

class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000
    };

    return (
      <section className="home-banner">
        <Slider {...settings}>
          <div>
            <Parallax
              blur={0}
              bgImage={"/images/home/home-banner-1.jpg"}
              bgImageAlt="Ohmyhotelnco"
              strength={500}
              contentClassName="parallax-content"
            >
              <div>
                <Fade>
                  <FormattedHTMLMessage tagName="h2" {...messages.title1} />

                  <FormattedHTMLMessage {...messages.subtitle1} />
                  <span className="header-line sort-line" />
                </Fade>
              </div>
            </Parallax>
          </div>
          <div>
            <Parallax
              blur={0}
              bgImage={"/images/home/home-banner-2.jpg"}
              bgImageAlt=""
              strength={200}
              contentClassName="parallax-content"
            >
              <div>
                <Fade>
                  <FormattedHTMLMessage {...messages.title2} />
                  <span className="header-line sort-line" />
                  <FormattedHTMLMessage {...messages.subtitle2} />
                </Fade>
              </div>
            </Parallax>
          </div>
        </Slider>
      </section>
    );
  }
}

export default injectIntl(HomeBanner);
