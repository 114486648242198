import React from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

import "./SubMenu.scss";

class SubMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageActive: ""
    };
  }

  componentDidMount() {
    setTimeout(() => {
      var x = document.querySelector(".nav .active");
      if (x) document.querySelector(".nav").scrollLeft = x.offsetLeft;
    }, 3000);
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      document.getElementById("subMenu").classList.add("for-ie11");
      window.onscroll = function() {
        var top = window.pageYOffset;
        if (top >= 290) {
          document
            .getElementById("subMenu")
            .setAttribute("style", "position: fixed; top: 7.8rem");
        } else {
          document
            .getElementById("subMenu")
            .setAttribute("style", "position: relative; top: 0");
        }
      };
    }
  }

  render() {
    setTimeout(() => {
      var x = document.querySelector(".nav .active");
      if (x) document.querySelector(".nav").scrollLeft = x.offsetLeft;
    }, 0);
    const { match, items } = this.props;
    const pageActive = window.location.pathname.split("/")[2];
    return (
      <div className="sub-menu" id="subMenu">
        <Nav>
          {items.map((item, i) => (
            <NavItem
              key={i}
              className={classnames({
                active: pageActive === item.path
              })}
            >
              <NavLink tag={Link} to={`${match.url}/${item.path}`}>
                {item.name}
              </NavLink>
              <span className="header-line sort-line" />
            </NavItem>
          ))}
          {/* <li className="blur-area" /> */}
        </Nav>
      </div>
    );
  }
}

export default SubMenu;
