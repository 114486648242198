import React from "react";
//import { BrowserRouter, Route } from "react-router-dom";
import HomePage from "../layout/pages/HomePage";
import AboutUsPage from "../layout/pages/AboutUsPage";
import BusinessPage from "../layout/pages/BusinessPage";
import CareersPage from "../layout/pages/CareersPage";
import PrivacyPage from "../layout/pages/PrivacyPage";

import { Route, Router, Switch } from "react-router-dom";
import history from "../helpers/history";

class AppRouter extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/about-us" component={AboutUsPage} />
          <Route path="/business" component={BusinessPage} />
          <Route path="/careers" component={CareersPage} />
          <Route path="/privacy-policy" component={PrivacyPage} />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
