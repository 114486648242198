import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "CMS.theSimplest.header",
    defaultMessage:
      "It's the simplest of all. <br/>Just the most important thing."
  },
  subHeader: {
    id: "CMS.theSimplest.subHeader",
    defaultMessage:
      "<strong><img src='/images/logo/name-black.svg' height='15' alt=''><br /> CMS</strong>"
  },
  title1: {
    id: "CMS.theSimplest.title1",
    defaultMessage: "Simple and easy interface"
  },
  content1: {
    id: "CMS.theSimplest.contnet1",
    defaultMessage:
      "Free yourself from the extranet for each OTA. Simply register the charges and inventory from one flat-home to each channel."
  },
  title2: {
    id: "CMS.theSimplest.title2",
    defaultMessage: "Fast and accurate charge/stock management"
  },
  content2: {
    id: "CMS.theSimplest.contnet2",
    defaultMessage:
      "Please focus on fares and inventory registration. Registered charges and inventories for each room are supplied to each channel in real time. You can also manage the room smartly through the inventory by channel."
  },
  title3: {
    id: "CMS.theSimplest.title3",
    defaultMessage: "Reduce costs and increase profits"
  },
  content3: {
    id: "CMS.theSimplest.contnet3",
    defaultMessage:
      "The hotel can manage multiple channels at once, and the channels can be booked more easily and conveniently. A single online room setting prevents automatic inventory exhaustion, double booking, and overbooking. Now market our hotel to customers around the world. Your company already has enough people."
  }
});
class CMSTheSimplest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="cms-the-simplest-section">
        <Fade distance="2rem" bottom>
          <div className="section-header">
            <FormattedHTMLMessage tagName="h3" {...messages.header} />
            <span className="header-line" />
          </div>
          <Container>
            <div className="section-content">
              <FormattedHTMLMessage {...messages.subHeader} />

              <Row className="align-left">
                <Col
                  lg={{ size: 6, order: 1 }}
                  md={{ size: 12, order: 2 }}
                  sm={{ size: 12, order: 2 }}
                  xs={{ size: 12, order: 2 }}
                >
                  <div>
                    <h6 className="p-title">
                      {formatMessage(messages.title1)}
                    </h6>
                    <p>{formatMessage(messages.content1)}</p>
                  </div>
                  <div>
                    <h6 className="p-title">
                      {formatMessage(messages.title2)}
                    </h6>
                    <p>{formatMessage(messages.content2)}</p>
                  </div>
                  <div>
                    <h6 className="p-title">
                      {formatMessage(messages.title3)}
                    </h6>
                    <p>{formatMessage(messages.content3)}</p>
                  </div>
                </Col>
                <Col
                  lg={{ size: 6, order: 2 }}
                  md={{ size: 12, order: 1 }}
                  sm={{ size: 12, order: 1 }}
                  xs={{ size: 12, order: 1 }}
                >
                  <img src="/images/business/CMS/cms.svg" alt="" />
                </Col>
              </Row>
            </div>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(CMSTheSimplest);
