import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "home.twothings.header",
    defaultMessage: "Two things you need right now.<br />We know."
  },
  title1: {
    id: "home.twothings.title1",
    defaultMessage: "Cost reduction"
  },
  content1: {
    id: "home.twothings.content1",
    defaultMessage:
      "Free yourself from manual reservation, overbooking. <br />Your company has enough people."
  },
  title2: {
    id: "home.twothings.title2",
    defaultMessage: "Increase in sales"
  },
  content2: {
    id: "home.twothings.content2",
    defaultMessage:
      "Increase efficiency and maximize sales of reservations <br />and accommodation with a purpose-built hotel integration system."
  }
});

class HomeTwoThings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="two-things-section">
        <Fade distance="2rem" bottom>
          <Container className="section-header">
            <FormattedHTMLMessage tagName="h3" {...messages.header} />
            <span className="header-line" />
          </Container>
          <Container className="section-content">
            <Row>
              <Col md="6">
                <img
                  src="/images/home/cost-reduction.svg"
                  alt=""
                  className="cost-reduction-img"
                />
                <h5 className="p-title">{formatMessage(messages.title1)}</h5>
                <div className="p-content">
                  <FormattedHTMLMessage {...messages.content1} />
                </div>
              </Col>
              <Col md="6">
                <img src="/images/home/increase-sales.svg" alt="" />
                <h5 className="p-title">{formatMessage(messages.title2)}</h5>
                <div className="p-content">
                  <FormattedHTMLMessage {...messages.content2} />
                </div>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(HomeTwoThings);
