import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "PMS.justInTime.header",
    defaultMessage: "Just in time for our hotel.<br/>Now select PMS as well."
  },
  subHeader1: {
    id: "PMS.justInTime.subHeader1",
    defaultMessage: "Full package for hotelier"
  },
  subHeader2: {
    id: "PMS.justInTime.subHeader2",
    defaultMessage: "Packages for small and medium sized accommodation"
  },
  title1: {
    id: "PMS.justInTime.title1",
    defaultMessage: "Quick bookings, faster check-in"
  },
  content1: {
    id: "PMS.justInTime.content1",
    defaultMessage:
      "Don't keep customers waiting.<br/>Book quickly on one platform and check in customers faster. <ul><li>Individuals and groups can be booked</li><li>Copy and paste functions, guest history management</li><li>Room type, allocation and change immediately after inventory check</li></ul>"
  },
  title2: {
    id: "PMS.justInTime.title2",
    defaultMessage: "Intuitive and complete accommodation management"
  },
  content2: {
    id: "PMS.justInTime.content2",
    defaultMessage:
      "View and manage the accommodation status by date and room at a glance. It can be viewed on a weekly or monthly basis.<li>Room type and room level can be checked.<ul></li><li>Check and shortcut detailed accommodation status (change of room, settlement, memo, etc.)</li><li>Download Accommodation Status Excel by Date</li></ul>"
  },
  title3: {
    id: "PMS.justInTime.title3",
    defaultMessage: "Advanced Analysis Report"
  },
  content3: {
    id: "PMS.justInTime.content3",
    defaultMessage:
      "View key performance indicators in a comprehensive and flexible report.<ul><li>Daily, historical summary of reports, and detailed options</li><li>Download Excel and print online</li></ul>"
  }
});
class PMSJustInTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="pms-just-in-time-section">
        <Fade distance="2rem" bottom>
          <div className="section-header">
            <FormattedHTMLMessage tagName="h3" {...messages.header} />
            <span className="header-line" />
          </div>
        </Fade>

        <Container>
          <Row className="section-content">
            <Col md="6" className="big-img">
              <Fade distance="2rem" bottom>
                <img
                  src="/images/business/PMS/full-package-hotelier.jpg"
                  alt=""
                />
                <h6 className="p-title">
                  {formatMessage(messages.subHeader1)}
                </h6>
              </Fade>
            </Col>

            <Col md="6" className="big-img">
              <Fade distance="2rem" bottom>
                <img src="/images/business/PMS/accommodation.jpg" alt="" />
                <h6
                  className="p-title"
                  style={{ maxWidth: "31.5rem", margin: "auto" }}
                >
                  {formatMessage(messages.subHeader2)}
                </h6>
              </Fade>
            </Col>
            <Col
              md={{ size: 6, order: 3 }}
              sm={{ size: 12, order: 3 }}
              xs={{ size: 12, order: 3 }}
              className="align-right"
            >
              <Fade distance="2rem" bottom>
                <img src="/images/business/PMS/faster-check-in.svg" alt="" />
              </Fade>
            </Col>
            <Col
              md={{ size: 6, order: 4 }}
              sm={{ size: 12, order: 4 }}
              xs={{ size: 12, order: 4 }}
              className="align-left"
            >
              <Fade distance="2rem" bottom>
                <div>
                  <h6 className="p-title">{formatMessage(messages.title1)}</h6>
                  <FormattedHTMLMessage {...messages.content1} />
                </div>
              </Fade>
            </Col>
            <Col
              md={{ size: 6, order: 5 }}
              sm={{ size: 12, order: 6 }}
              xs={{ size: 12, order: 6 }}
              className="align-left"
            >
              <Fade distance="2rem" bottom>
                <div>
                  <h6 className="p-title">{formatMessage(messages.title2)}</h6>
                  <FormattedHTMLMessage {...messages.content2} />
                </div>
              </Fade>
            </Col>
            <Col
              md={{ size: 6, order: 6 }}
              sm={{ size: 12, order: 5 }}
              xs={{ size: 12, order: 5 }}
              className="align-left"
            >
              <Fade distance="2rem" bottom>
                <img
                  src="/images/business/PMS/accommodation-management.svg"
                  alt=""
                />
              </Fade>
            </Col>
            <Col
              md={{ size: 6, order: 7 }}
              sm={{ size: 12, order: 7 }}
              xs={{ size: 12, order: 7 }}
              className="align-right"
            >
              <Fade distance="2rem" bottom>
                <img src="/images/business/PMS/analysis-report.svg" alt="" />
              </Fade>
            </Col>
            <Col
              md={{ size: 6, order: 8 }}
              sm={{ size: 12, order: 8 }}
              xs={{ size: 12, order: 8 }}
              className="align-left"
            >
              <Fade distance="2rem" bottom>
                <div>
                  <h6 className="p-title">{formatMessage(messages.title3)}</h6>
                  <FormattedHTMLMessage {...messages.content3} />
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default injectIntl(PMSJustInTime);
