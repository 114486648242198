import React from "react";
import { Fragment } from 'react';
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container } from "reactstrap";
import { Fade } from "react-reveal";
import "./Location.scss";

const messages = defineMessages({
  header: {
    id: "location.header",
    defaultMessage: "Location"
  },
  location1: {
    id: "location.location1",
    defaultMessage: "Seoul, Korea (HQ)"
  },
  address1: {
    id: "location.address1",
    defaultMessage:
      "6F, GT Dongdaemun B/D, 328, Jong-ro, Jongno-gu, Seoul, Republic of Korea"
  },
  contact1: {
    id: "location.contact1",
    defaultMessage:
      `<div><img src="/images/footer/phone.svg" alt="" />+82-2-733-0550</div><div><img src="/images/footer/fax.svg" alt="" />+82-2-733-0551</div>`
  },
  flag1: {
    id: "location.flag1",
    defaultMessage: "ko"
  },
  location2: {
    id: "location.location2",
    defaultMessage: "Osaka, Japan"
  },
  address2: {
    id: "location.address2",
    defaultMessage: "3F, Showa Bldg, 2-5-24, Nishi-Honmachi, Nishi-ku, Osaka-shi, Osaka 550-0005 Japan"
  },
  contact2: {
    id: "location.contact2",
    defaultMessage:
      `<div><img src="/images/footer/phone.svg" alt="" />+81-6-6940-7949</div><div><img src="/images/footer/fax.svg" alt="" />+81-6-6940-7948 <span>/</span> +81-3-4531-8229</div>`
  },
  flag2: {
    id: "location.flag2",
    defaultMessage: "ja"
  },
  location3: {
    id: "location.location3",
    defaultMessage: "Ho Chi Minh, Vietnam"
  },
  address3: {
    id: "location.address3",
    defaultMessage: "Unit F7-BCD, 7th Floor, Mach Building, 127 Ung Van Khiem, Ward 25, Binh Thanh District, Ho Chi Minh City, Vietnam"
  },
  contact3: {
    id: "location.contact3",
    defaultMessage:
      `<div><img src="/images/footer/phone.svg" alt="" />+84-28-3519-4629</div>`
  },
  flag3: {
    id: "location.flag3",
    defaultMessage: "vi"
  },
});
class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <div className="location-section">
        <section>
          <Fade distance="2rem" bottom>
            <div className="section-header">
              <h3>{formatMessage(messages.header)}</h3>
              <span className="header-line" />
            </div>
            <div className="section-content">
              <Container>
                <Fragment>
                  <div className="line" data-branch={formatMessage(messages.flag1)}></div>
                  <span className="lang-flag">
                    <img src={"/images/flags/" + formatMessage(messages.flag1) + ".svg"} alt="" />
                  </span>
                  <h6>
                    {formatMessage(messages.location1)}
                  </h6>
                  <div>
                    <FormattedHTMLMessage tagName="p" {...messages.address1}></FormattedHTMLMessage>
                    <FormattedHTMLMessage tagName="div" {...messages.contact1}></FormattedHTMLMessage>
                  </div>
                </Fragment>
                <Fragment>
                  <div className="line" data-branch={formatMessage(messages.flag2)}></div>

                  <span className="lang-flag">
                    <img src={"/images/flags/" + formatMessage(messages.flag2) + ".svg"} alt="" />
                  </span>
                  <h6>
                    {formatMessage(messages.location2)}
                  </h6>
                  <div>
                    <FormattedHTMLMessage tagName="p" {...messages.address2}></FormattedHTMLMessage>
                    <FormattedHTMLMessage tagName="div" {...messages.contact2}></FormattedHTMLMessage>
                  </div>
                </Fragment>
                <Fragment>
                  <div className="line" data-branch={formatMessage(messages.flag3)}></div>
                  <span className="lang-flag">
                    <img src={"/images/flags/" + formatMessage(messages.flag3) + ".svg"} alt="" />
                  </span>
                  <h6>
                    {formatMessage(messages.location3)}
                  </h6>
                  <div>
                    <FormattedHTMLMessage tagName="p" {...messages.address3}></FormattedHTMLMessage>
                    <FormattedHTMLMessage tagName="div" {...messages.contact3}></FormattedHTMLMessage>
                  </div>
                </Fragment>
              </Container>
            </div>
          </Fade>
        </section>
      </div>
    );
  }
}

export default injectIntl(Location);
