import React from "react";
import CMSIntro from "./CMSIntro";
import CMSTheSimplest from "./CMSTheSimplest";
import CMSAskForEmail from "./CMSAskForEmail";

import "./CMS.scss";
class CMS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <CMSIntro />
        <CMSTheSimplest />
        <CMSAskForEmail />
      </div>
    );
  }
}

export default CMS;
