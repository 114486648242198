import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col, Card } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "home.yourFriendConfidant.header",
    defaultMessage: "OHMYHOTEL & CO"
  },
  quote: {
    id: "home.yourFriendConfidant.quote",
    defaultMessage: "Your Friend & Confidant"
  },
  ourSlogans: {
    id: "home.yourFriendConfidant.ourSlogans",
    defaultMessage: "Our slogans"
  },
  titte1: {
    id: "home.yourFriendConfidant.titte1",
    defaultMessage: "SOCIETY"
  },
  titte2: {
    id: "home.yourFriendConfidant.titte2",
    defaultMessage: "TECHNOLOGY"
  },
  titte3: {
    id: "home.yourFriendConfidant.titte3",
    defaultMessage: "HAPPY"
  },
  content1: {
    id: "home.yourFriendConfidant.content1",
    defaultMessage:
      "We dream of a community and create a society that lives together. I value the environment and people the most and fulfill my corporate social responsibility."
  },
  content2: {
    id: "home.yourFriendConfidant.content2",
    defaultMessage:
      "We add travel to IT and we rapidly approach changes in the digital ecosystem. All executives and employees strive to provide services that are loved and trusted with the highest priority of customer satisfaction."
  },
  content3: {
    id: "home.yourFriendConfidant.content3",
    defaultMessage:
      "In order to make the workplace happy and pleasant, various welfare systems and events are held with an emphasis on employee convenience and happiness."
  }
});

class HomeYourFriendConfidant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="your-friend-confidant-section">
        <Fade distance="2rem" bottom>
          <div className="section-header align-center">
            {/* <h3>
              {formatMessage(messages.ourSlogans)}
              <img src="/images/logo/name-black.svg" alt="" />
            </h3> */}
            <h4 className="quote">{formatMessage(messages.quote)}</h4>
            <span className="header-line" />
          </div>
          <Container className="section-content">
            <Row>
              <Col md="4">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/home/society.svg" alt="society" />
                  </div>

                  <h6 className="p-title">{formatMessage(messages.titte1)}</h6>
                  <FormattedHTMLMessage tagName="p" {...messages.content1} />
                </Card>
              </Col>
              <Col md="4">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/home/technology.svg" alt="technology" />
                  </div>

                  <h6 className="p-title">{formatMessage(messages.titte2)}</h6>
                  <FormattedHTMLMessage tagName="p" {...messages.content2} />
                </Card>
              </Col>
              <Col md="4">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/home/happy.svg" alt="happy" />
                  </div>

                  <h6 className="p-title">{formatMessage(messages.titte3)}</h6>
                  <FormattedHTMLMessage tagName="p" {...messages.content3} />
                </Card>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(HomeYourFriendConfidant);
