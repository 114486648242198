import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col, Card } from "reactstrap";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

const messages = defineMessages({
  header: {
    id: "home.allInOne.header",
    defaultMessage: "All-in-one, Seamless Solution"
  },
  title1: {
    id: "home.allInOne.title1",
    defaultMessage: "OTA"
  },
  content1: {
    id: "home.allInOne.content1",
    defaultMessage: "<li>Easy booking</li><li>Low price</li>"
  },
  title2: {
    id: "home.allInOne.title2",
    defaultMessage: "Web site"
  },
  content2: {
    id: "home.allInOne.content2",
    defaultMessage:
      "<li>A more attractive hotel</li><li>User-centric design</li>"
  },
  title3: {
    id: "home.allInOne.title3",
    defaultMessage: "Booking Engine"
  },
  content3: {
    id: "home.allInOne.content3",
    defaultMessage: "<li>Quick hotel registration</li><li>PMS Interlink</li>"
  },
  title4: {
    id: "home.allInOne.title4",
    defaultMessage: "PMS"
  },
  content4: {
    id: "home.allInOne.content4",
    defaultMessage:
      "<li>Convenient reservation check</li><li>Intuitive accommodation</li>"
  },
  title5: {
    id: "home.allInOne.title5",
    defaultMessage: "CMS"
  },
  content5: {
    id: "home.allInOne.content5",
    defaultMessage:
      "<li>Efficient rate/ inventory management</li><li>Simple Interface</li>"
  },
  title6: {
    id: "home.allInOne.title6",
    defaultMessage: "Hotel Management"
  },
  content6: {
    id: "home.allInOne.content6",
    defaultMessage: "To be opened"
  },
  learnMore: {
    id: "home.allInOne.learnMore",
    defaultMessage: "Learn More"
  }
});

class HomeAllInOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="all-in-one-section">
        <Fade distance="2rem" bottom>
          <div className="section-header">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </div>
          <Container className="section-content">
            <Row>
              <Col md="6" lg="4">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/home/ota.svg" alt="OTA" />
                  </div>

                  <h6 className="p-title">{formatMessage(messages.title1)}</h6>
                  <div className="p-content">
                    <FormattedHTMLMessage tagName="ul" {...messages.content1} />
                    <Link to="/business/online-booking-engine">
                      {formatMessage(messages.learnMore)}
                      <img src="/images/icons/chevron.svg" alt=">" />
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/home/website.svg" alt="website" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title2)}</h6>
                  <div className="p-content">
                    <FormattedHTMLMessage tagName="ul" {...messages.content2} />
                    <Link to="/business/hotel-web-design#beWebsite">
                      {formatMessage(messages.learnMore)}
                      <img src="/images/icons/chevron.svg" alt=">" />
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card body>
                  <div className="item-icon">
                    <img
                      src="/images/home/booking-engine.svg"
                      alt="booking-engine"
                    />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title3)}</h6>
                  <div className="p-content">
                    <FormattedHTMLMessage tagName="ul" {...messages.content3} />
                    <Link to="/business/hotel-web-design#bookingEngine">
                      {formatMessage(messages.learnMore)}
                      <img src="/images/icons/chevron.svg" alt=">" />
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/home/pms.svg" alt="pms" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title4)}</h6>
                  <div className="p-content">
                    <FormattedHTMLMessage tagName="ul" {...messages.content4} />
                    <Link to="/business/pms">
                      {formatMessage(messages.learnMore)}
                      <img src="/images/icons/chevron.svg" alt=">" />
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/home/cms.svg" alt="cms" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title5)}</h6>
                  <div className="p-content">
                    <FormattedHTMLMessage tagName="ul" {...messages.content5} />
                    <Link to="/business/cms">
                      {formatMessage(messages.learnMore)}
                      <img src="/images/icons/chevron.svg" alt=">" />
                    </Link>
                  </div>
                </Card>
              </Col>
              {/* <Col md="6" lg="4">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/home/hotel.svg" alt="hotel" />
                  </div>
                  <h6 className="p-title">{formatMessage(messages.title6)}</h6>
                  <div className="p-content">
                    <p className="blur-text">
                      {formatMessage(messages.content6)}
                    </p>
                  </div>
                </Card>
              </Col> */}
            </Row>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(HomeAllInOne);
