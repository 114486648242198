import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col, Card } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "BE.bookingEngine.header",
    defaultMessage: "Booking Engine"
  },
  commonContent: {
    id: "BE.bookingEngine.commonContent",
    defaultMessage:
      "Booking modules for the Ohmyhotel &Co Bushing Engine (EZ-BOOK) are fully responsive and can be customized for your website."
  },
  title1: {
    id: "BE.bookingEngine.title1",
    defaultMessage: "Booking Engine"
  },
  title2: {
    id: "BE.bookingEngine.title2",
    defaultMessage: "PMS"
  },
  content3: {
    id: "BE.bookingEngine.content3",
    defaultMessage: "Easy Hotel Registration"
  },
  content4: {
    id: "BE.bookingEngine.content4",
    defaultMessage: "Online Payment"
  },
  content1: {
    id: "BE.bookingEngine.content1",
    defaultMessage: "Efficient inventory, rate management"
  },
  content2: {
    id: "BE.bookingEngine.content2",
    defaultMessage: "Quick reservation, convenient reservation check"
  },
  content5: {
    id: "BE.bookingEngine.content5",
    defaultMessage:
      "The hotel booking engine works perfectly with the Ohmyhotel PMS to improve the efficiency of reservation."
  }
});
class BEBookingEngine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="be-booking-engine-section" id="bookingEngine">
        <Fade distance="2rem" bottom>
          <Container className="section-header align-center">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </Container>
          <Container className="section-content">
            <div className="common-content">
              <FormattedHTMLMessage {...messages.commonContent} />
            </div>
            <div className="be-image">
              <img
                src="/images/business/BE/hotel-booking-engine-1.jpg"
                width="742"
                alt=""
              />
            </div>
            <Row>
              <Col md="3">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/business/BE/efficient.svg" alt=" 1" />
                  </div>
                  <p>{formatMessage(messages.content1)}</p>
                </Card>
              </Col>
              <Col md="3">
                <Card body>
                  <div className="item-icon">
                    <img src="/images/business/BE/reservation.svg" alt=" 1" />
                  </div>

                  <p>{formatMessage(messages.content2)}</p>
                </Card>
              </Col>
              <Col md="3">
                <Card body>
                  <div className="item-icon">
                    <img
                      src="/images/business/BE/hotel-registration.svg"
                      alt=" 1"
                    />
                  </div>

                  <p>{formatMessage(messages.content3)}</p>
                </Card>
              </Col>
              <Col md="3">
                <Card body>
                  <div className="item-icon">
                    <img
                      src="/images/business/BE/online-payment.svg"
                      alt=" 1"
                    />
                  </div>

                  <p>{formatMessage(messages.content4)}</p>
                </Card>
              </Col>
            </Row>
            <div className="be-message">
              <img
                src="/images/business/BE/hotel-booking-engine-2.svg"
                alt=""
              />
              <h6>{formatMessage(messages.content5)}</h6>
            </div>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(BEBookingEngine);
