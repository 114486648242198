import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import { Fade } from "react-reveal";
import { Container } from "reactstrap";

const messages = defineMessages({
  header: {
    id: "CMS.askForEmail.header",
    defaultMessage: "Systems that best sell as many rooms as possible",
  },
  askForEmail: {
    id: "CMS.askForEmail.askForEmail",
    defaultMessage: "Ask for mail ",
  },
});

class CMSAskForEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <section className="cms-afe-section">
        <Fade distance="2rem" bottom>
          <Container className="section-content">
            <h5>{formatMessage(messages.header)}</h5>
            <a href="mailto:cs@ohmyhotel.com" className="btn btn-primary">
              <img src="/images/icons/new-email-outline.svg" alt="" />
              {formatMessage(messages.askForEmail)}
              <img src="/images/icons/chevron-white.svg" alt=">" />
            </a>
          </Container>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(CMSAskForEmail);
